(function () {
    angular.module('informaApp')
        .component('emailVerification', {
            controller: EmailVerificationController,
            templateUrl: 'components/email-verification/template.html'
        });

    function EmailVerificationController(AuthenticationService, $window) {
        this.goToEmailVerificationPage = () => {
            AuthenticationService.generateEmailVerificationUrl()
                .then(url => $window.location.href = url);
        }
    }
})();
